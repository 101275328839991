import { FunctionalComponent, h } from "preact";
import * as style from "./style.css";

const Loading: FunctionalComponent = () => {
    return <div className={style.container}>
        <div className={style["lds-roller"]}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
};

export default Loading;
