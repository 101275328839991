import { Colour, Pattern, Product, ThreeDimensional, TwoDimensional } from "./types";

const VANILLA_COLOURS: Array<Colour> = [
    {
        key: "1",
        hex: "#00bbe3"
    },
    {
        key: "2",
        hex: "#6ce0e4"
    },
    {
        key: "3",
        hex: "#ff9700"
    },
    {
        key: "4",
        hex: "#f6463e"
    },
    {
        key: "5",
        hex: "#b7006a"
    },
    {
        key: "6",
        hex: "#3dc5d4"
    },
    {
        key: "7",
        hex: "#ffc12f"
    },
    {
        key: "8",
        hex: "#ff6924"
    },
    {
        key: "9",
        hex: "#ff4222"
    },
    {
        key: "10",
        hex: "#c5006c"
    }
];

import Chic2dImg from "../assets/products/vanilla/2d/chic.png";
import Energetic2dImg from "../assets/products/vanilla/2d/energetic.png";
import Fantasy2dImg from "../assets/products/vanilla/2d/fantasy.png";
import Fly2dImg from "../assets/products/vanilla/2d/fly.png";
import Glam2dImg from "../assets/products/vanilla/2d/glam.png";
import Hypnotic2dImg from "../assets/products/vanilla/2d/hypnotic.png";
import Indulgent2dImg from "../assets/products/vanilla/2d/indulgent.png";
import Posh2dImg from "../assets/products/vanilla/2d/posh.png";
import Sassy2dImg from "../assets/products/vanilla/2d/sassy.png";
import Sleek2dImg from "../assets/products/vanilla/2d/sleek.png";

import Chic2dAnimation from "../assets/products/vanilla/2d/chic.webm";
import Energetic2dAnimation from "../assets/products/vanilla/2d/energetic.webm";
import Fantasy2dAnimation from "../assets/products/vanilla/2d/fantasy.webm";
import Fly2dAnimation from "../assets/products/vanilla/2d/fly.webm";
import Glam2dAnimation from "../assets/products/vanilla/2d/glam.webm";
import Hypnotic2dAnimation from "../assets/products/vanilla/2d/hypnotic.webm";
import Indulgent2dAnimation from "../assets/products/vanilla/2d/indulgent.webm";
import Posh2dAnimation from "../assets/products/vanilla/2d/posh.webm";
import Sassy2dAnimation from "../assets/products/vanilla/2d/sassy.webm";
import Sleek2dAnimation from "../assets/products/vanilla/2d/sleek.webm";

const VANILLA_TWO_DIMENSIONAL: Array<TwoDimensional> = [
    {
        key: "CHIC",
        image: Chic2dImg,
        animation: Chic2dAnimation,
        name: "Chic"
    },
    {
        key: "ENERGETIC",
        image: Energetic2dImg,
        animation: Energetic2dAnimation,
        name: "Energetic"
    },
    {
        key: "FANTASY",
        image: Fantasy2dImg,
        animation: Fantasy2dAnimation,
        name: "Fantasy"
    },
    {
        key: "FLY",
        image: Fly2dImg,
        animation: Fly2dAnimation,
        name: "Fly"
    },
    {
        key: "GLAM",
        image: Glam2dImg,
        animation: Glam2dAnimation,
        name: "Glam"
    },
    {
        key: "HYPNOTIC",
        image: Hypnotic2dImg,
        animation: Hypnotic2dAnimation,
        name: "Hypnotic"
    },
    {
        key: "INDULGENT",
        image: Indulgent2dImg,
        animation: Indulgent2dAnimation,
        name: "Indulgent"
    },
    {
        key: "POSH",
        image: Posh2dImg,
        animation: Posh2dAnimation,
        name: "Posh"
    },
    {
        key: "SASSY",
        image: Sassy2dImg,
        animation: Sassy2dAnimation,
        name: "Sassy"
    },
    {
        key: "SLEEK",
        image: Sleek2dImg,
        animation: Sleek2dAnimation,
        name: "Sleek"
    }
];

import Boujee3dImg from "../assets/products/vanilla/3d/boujee.png";
import Fierce3dImg from "../assets/products/vanilla/3d/fierce.png";
import Lush3dImg from "../assets/products/vanilla/3d/lush.png";
import Onfleek3dImg from "../assets/products/vanilla/3d/onfleek.png";
import Savage3dImg from "../assets/products/vanilla/3d/savage.png";
import Set3dImg from "../assets/products/vanilla/3d/set.png";
import Sharp3dImg from "../assets/products/vanilla/3d/sharp.png";
import Slaying3dImg from "../assets/products/vanilla/3d/slaying.png";
import Snazzy3dImg from "../assets/products/vanilla/3d/snazzy.png";
import Swagger3dImg from "../assets/products/vanilla/3d/swagger.png";

const VANILLA_THREE_DIMENSIONAL: Array<ThreeDimensional> = [
    {
        key: "BOUJEE",
        image: Boujee3dImg,
        name: "Ajebutter"
    },
    {
        key: "FIERCE",
        image: Fierce3dImg,
        name: "Fierce"
    },
    {
        key: "LUSH",
        image: Lush3dImg,
        name: "Lush"
    },
    {
        key: "ONFLEEK",
        image: Onfleek3dImg,
        name: "On Fleek"
    },
    {
        key: "SAVAGE",
        image: Savage3dImg,
        name: "Savage"
    },
    {
        key: "SET",
        image: Set3dImg,
        name: "Set"
    },
    {
        key: "SHARP",
        image: Sharp3dImg,
        name: "Sharp"
    },
    {
        key: "SLAYING",
        image: Slaying3dImg,
        name: "Slayin'"
    },
    {
        key: "SNAZZY",
        image: Snazzy3dImg,
        name: "Slick"
    },
    {
        key: "SWAGGER",
        image: Swagger3dImg,
        name: "Swagger"
    }
];

import AiryPatternImg from "../assets/products/vanilla/patterns/airy.png";
import BitterSweetPatternImg from "../assets/products/vanilla/patterns/bitter-sweet.png";
import CreamyPatternImg from "../assets/products/vanilla/patterns/creamy.png";
import DelicatePatternImg from "../assets/products/vanilla/patterns/delicate.png";
import DreamyPatternImg from "../assets/products/vanilla/patterns/dreamy.png";
import FancyPatternImg from "../assets/products/vanilla/patterns/fancy.png";
import RichPatternImg from "../assets/products/vanilla/patterns/rich.png";
import SilkyPatternImg from "../assets/products/vanilla/patterns/silky.png";
import SmoothSensationPatternImg from "../assets/products/vanilla/patterns/smooth-sensation.png";
import VelveliciousPatternImg from "../assets/products/vanilla/patterns/velvelicious.png";

const VANILLA_PATTERNS: Array<Pattern> = [
    {
        key: "AIRY",
        image: AiryPatternImg,
        name: "Airy"
    },
    {
        key: "BITTER_SWEET",
        image: BitterSweetPatternImg,
        name: "Bittersweet"
    },
    {
        key: "CREAMY",
        image: CreamyPatternImg,
        name: "Creamy"
    },
    {
        key: "DELICATE",
        image: DelicatePatternImg,
        name: "Delicate"
    },
    {
        key: "DREAMY",
        image: DreamyPatternImg,
        name: "Dreamy"
    },
    {
        key: "FANCY",
        image: FancyPatternImg,
        name: "Fancy"
    },
    {
        key: "RICH",
        image: RichPatternImg,
        name: "Rich"
    },
    {
        key: "SILKY",
        image: SilkyPatternImg,
        name: "Silky"
    },
    {
        key: "SMOOTH",
        image: SmoothSensationPatternImg,
        name: "Smooth Sensation"
    },
    {
        key: "VELVELICIOUS",
        image: VelveliciousPatternImg,
        name: "Velvelicious"
    }
];

import VanillaCanImg from "../assets/products/vanilla/can.png";

const VANILLA_PRODUCT: Product = {
    key: "VANILLA",
    image: VanillaCanImg
};

export { VANILLA_COLOURS, VANILLA_TWO_DIMENSIONAL, VANILLA_THREE_DIMENSIONAL, VANILLA_PATTERNS, VANILLA_PRODUCT };