const loadImageAsync = (url: string): Promise<HTMLImageElement> => {
    const image = new Image();

    image.src = url;

    return new Promise<HTMLImageElement>((resolve, reject) => {
        image.onload = () => {
            resolve(image);
        }

        image.onerror = (err) => {
            reject(err)
        }
    });
}

export default loadImageAsync;