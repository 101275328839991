import { FunctionalComponent, h } from "preact";
import * as style from "./style.css";

interface Props {
    href: string;
    onClick?: (e: any) => void;
    outline?: boolean;
    large?: boolean;
}

const Button: FunctionalComponent<Props> = ({ children, href, onClick, outline, large }) => {
    return <a className={`${style.button} ${outline ? style.outline : ''} ${large ? style.large : ''}`}
        href={href}
        onClick={onClick}>
        {children}
    </a>;
};

export default Button;
