import { Colour, Pattern, Product, ThreeDimensional, TwoDimensional } from "./types";

const PINEAPPLE_COLOURS: Array<Colour> = [
    {
        key: "1",
        hex: "#fdfb3c"
    },
    {
        key: "2",
        hex: "#ff9a00"
    },
    {
        key: "3",
        hex: "#ffa342"
    },
    {
        key: "4",
        hex: "#5effe2"
    },
    {
        key: "5",
        hex: "#fb4d00"
    },
    {
        key: "6",
        hex: "#00d2e2"
    },
    {
        key: "7",
        hex: "#e1f460"
    },
    {
        key: "8",
        hex: "#a4e23f"
    },
    {
        key: "9",
        hex: "#53d54c"
    },
    {
        key: "10",
        hex: "#00bb92"
    }
];

import Artsy2dImg from "../assets/products/pineapple/2d/artsy.png";
import Bold2dImg from "../assets/products/pineapple/2d/bold.png";
import Edgy2dImg from "../assets/products/pineapple/2d/edgy.png";
import Exotic2dImg from "../assets/products/pineapple/2d/exotic.png";
import Flashy2dImg from "../assets/products/pineapple/2d/flashy.png";
import Fun2dImg from "../assets/products/pineapple/2d/fun.png";
import Funky2dImg from "../assets/products/pineapple/2d/funky.png";
import Sporty2dImg from "../assets/products/pineapple/2d/sporty.png";
import Tropical2dImg from "../assets/products/pineapple/2d/tropical.png";
import Vibey2dImg from "../assets/products/pineapple/2d/vibey.png";

import Artsy2dAnimation from "../assets/products/pineapple/2d/artsy.webm";
import Bold2dAnimation from "../assets/products/pineapple/2d/bold.webm";
import Edgy2dAnimation from "../assets/products/pineapple/2d/edgy.webm";
import Exotic2dAnimation from "../assets/products/pineapple/2d/exotic.webm";
import Flashy2dAnimation from "../assets/products/pineapple/2d/flashy.webm";
import Fun2dAnimation from "../assets/products/pineapple/2d/fun.webm";
import Funky2dAnimation from "../assets/products/pineapple/2d/funky.webm";
import Sporty2dAnimation from "../assets/products/pineapple/2d/sporty.webm";
import Tropical2dAnimation from "../assets/products/pineapple/2d/tropical.webm";
import Vibey2dAnimation from "../assets/products/pineapple/2d/vibey.webm";

const PINEAPPLE_TWO_DIMENSIONAL: Array<TwoDimensional> = [
    {
        key: "ARTSY",
        image: Artsy2dImg,
        animation: Artsy2dAnimation,
        name: "Artsy"
    },
    {
        key: "BOLD",
        image: Bold2dImg,
        animation: Bold2dAnimation,
        name: "Bold"
    },
    {
        key: "EDGY",
        image: Edgy2dImg,
        animation: Edgy2dAnimation,
        name: "Edgy"
    },
    {
        key: "EXOTIC",
        image: Exotic2dImg,
        animation: Exotic2dAnimation,
        name: "Exotic"
    },
    {
        key: "FLASHY",
        image: Flashy2dImg,
        animation: Flashy2dAnimation,
        name: "Flashy"
    },
    {
        key: "FUN",
        image: Fun2dImg,
        animation: Fun2dAnimation,
        name: "Fun"
    },
    {
        key: "FUNKY",
        image: Funky2dImg,
        animation: Funky2dAnimation,
        name: "Funky"
    },
    {
        key: "SPORTY",
        image: Sporty2dImg,
        animation: Sporty2dAnimation,
        name: "Sporty"
    },
    {
        key: "TROPICAL",
        image: Tropical2dImg,
        animation: Tropical2dAnimation,
        name: "Tropical"
    },
    {
        key: "VIBEY",
        image: Vibey2dImg,
        animation: Vibey2dAnimation,
        name: "Vibey"
    }
];

import Chilled3dImg from "../assets/products/pineapple/3d/chilled.png";
import Fire3dImg from "../assets/products/pineapple/3d/fire.png";
import FreeSpirit3dImg from "../assets/products/pineapple/3d/free-spirit.png";
import GhenGhen3dImg from "../assets/products/pineapple/3d/ghen-ghen.png";
import Grooving3dImg from "../assets/products/pineapple/3d/grooving.png";
import Passion3dImg from "../assets/products/pineapple/3d/passion.png";
import Playtime3dImg from "../assets/products/pineapple/3d/playtime.png";
import Popping3dImg from "../assets/products/pineapple/3d/popping.png";
import Vibing3dImg from "../assets/products/pineapple/3d/vibing.png";
import Wilding3dImg from "../assets/products/pineapple/3d/wilding.png";

const PINEAPPLE_THREE_DIMENSIONAL: Array<ThreeDimensional> = [
    {
        key: "CHILLED",
        image: Chilled3dImg,
        name: "Chilled"
    },
    {
        key: "FIRE",
        image: Fire3dImg,
        name: "Fire"
    },
    {
        key: "FREE_SPIRIT",
        image: FreeSpirit3dImg,
        name: "Free Spirit"
    },
    {
        key: "GHEN_GHEN",
        image: GhenGhen3dImg,
        name: "Ghen Ghen"
    },
    {
        key: "GROOVING",
        image: Grooving3dImg,
        name: "Groovin'"
    },
    {
        key: "PASSION",
        image: Passion3dImg,
        name: "Passion"
    },
    {
        key: "PLAYTIME",
        image: Playtime3dImg,
        name: "Playtime"
    },
    {
        key: "POPPING",
        image: Popping3dImg,
        name: "Poppin'"
    },
    {
        key: "VIBING",
        image: Vibing3dImg,
        name: "Vibin'"
    },
    {
        key: "WILDING",
        image: Wilding3dImg,
        name: "Wildin'"
    }
];

import CoolPatternImg from "../assets/products/pineapple/patterns/cool.png";
import CrispPatternImg from "../assets/products/pineapple/patterns/crisp.png";
import FlavourishlessPatternImg from "../assets/products/pineapple/patterns/flavourishless.png";
import FreshPatternImg from "../assets/products/pineapple/patterns/fresh.png";
import FruityPatternImg from "../assets/products/pineapple/patterns/fruity.png";
import JuicyPatternImg from "../assets/products/pineapple/patterns/juicy.png";
import QuenchyPatternImg from "../assets/products/pineapple/patterns/quenchy.png";
import SweetPatternImg from "../assets/products/pineapple/patterns/sweet.png";
import TangyPatternImg from "../assets/products/pineapple/patterns/tangy.png";
import ZestyPatternImg from "../assets/products/pineapple/patterns/zesty.png";

const PINEAPPLE_PATTERNS: Array<Pattern> = [
    {
        key: "COOL",
        image: CoolPatternImg,
        name: "Cool"
    },
    {
        key: "CRISP",
        image: CrispPatternImg,
        name: "Crisp"
    },
    {
        key: "FLAVOURISHLESS",
        image: FlavourishlessPatternImg,
        name: "Flavourlicious"
    },
    {
        key: "FRESH",
        image: FreshPatternImg,
        name: "Freeeshhh"
    },
    {
        key: "FRUITY",
        image: FruityPatternImg,
        name: "Fruity"
    },
    {
        key: "JUICY",
        image: JuicyPatternImg,
        name: "Juicy"
    },
    {
        key: "QUENCHY",
        image: QuenchyPatternImg,
        name: "Quenchy"
    },
    {
        key: "SWEET",
        image: SweetPatternImg,
        name: "Sweet"
    },
    {
        key: "TANGY",
        image: TangyPatternImg,
        name: "Tangy"
    },
    {
        key: "ZESTY",
        image: ZestyPatternImg,
        name: "Zesty"
    }
];

import PineappleCanImg from "../assets/products/pineapple/can.png";

const PINEAPPLE_PRODUCT: Product = {
    key: "PINEAPPLE",
    image: PineappleCanImg
};

export { PINEAPPLE_COLOURS, PINEAPPLE_TWO_DIMENSIONAL, PINEAPPLE_THREE_DIMENSIONAL, PINEAPPLE_PATTERNS, PINEAPPLE_PRODUCT };