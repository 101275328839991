import { FunctionalComponent, h } from "preact";
import * as style from "./style.css";
import Button from "../../components/button";
import StartImage from "./assets/start.svg";
import HeroImg from "./assets/hero.png";
import HeroImgWebP from "./assets/hero.webp";
import HeroH650Img from "./assets/hero-h650.png";
import HeroH650ImgWebP from "./assets/hero-h650.webp";
import { route } from 'preact-router';
import { useState, useContext } from 'preact/hooks';
import { generatePineappleJourney, generateVanillaJourney } from "../../journey";
import { GlobalContext } from "../../journey/GlobalContext";
import Loading from "../../components/loading";
import loadImageAsync from "../../utils/loadImageAsync";

const Home: FunctionalComponent = () => {
    const [globalContext, setGlobalContext] = useContext(GlobalContext);
    const [isLoading, setIsLoading] = useState(false);

    const handleStartClick = async (e: any) => {
        e.preventDefault();

        const journeys = [generateVanillaJourney(), generatePineappleJourney()];
        setGlobalContext({
            journeys: journeys,
            product: undefined,
            colour: undefined,
            pattern: undefined,
            twoDimensional: undefined,
            threeDimensional: undefined
        });

        const x = setTimeout(() => setIsLoading(true), 100);
        await Promise.all([loadImageAsync(journeys[0].product.image), loadImageAsync(journeys[1].product.image)]);
        clearTimeout(x);
        setIsLoading(false);

        route("/create");
    }

    return (
        <div className={style.home}>
            <div className={style.content}>
                <picture className={style.hero}>
                    <source type="image/webp" media="(max-height: 812px)" srcset={HeroH650ImgWebP} />
                    <source type="image/png" media="(max-height: 812px)" srcset={HeroH650Img} />

                    <source type="image/webp" media="(min-height: 813px)" srcset={HeroImgWebP} />
                    <source type="image/png" media="(min-height: 813px)" srcset={HeroImg} />
                    <img alt="" src={HeroImg}></img>
                </picture>
            </div>
            <div className={style.footer}>
                <h1 className={style.heading}>
                    Create your own <br />
                    Tastepiration!
                </h1>
                <a href="" onClick={handleStartClick} className={style.startButton}>
                    <img alt="Start" src={StartImage} />
                </a>
                <div className={style.footerButtons}>
                    <Button href="/top" outline={true}>TOP 10</Button>
                    <Button href="/terms" outline={true}>Ts & Cs</Button>
                </div>
                <p>By clicking the Start button, I agree with the Terms, Conditions and Privacy Policy</p>
            </div>
            {isLoading && <Loading />}
        </div>
    );
};

export default Home;
