import { VANILLA_COLOURS, VANILLA_TWO_DIMENSIONAL, VANILLA_THREE_DIMENSIONAL, VANILLA_PATTERNS, VANILLA_PRODUCT } from "./vanillaConstants";
import { PINEAPPLE_COLOURS, PINEAPPLE_TWO_DIMENSIONAL, PINEAPPLE_THREE_DIMENSIONAL, PINEAPPLE_PATTERNS, PINEAPPLE_PRODUCT } from "./pineappleConstants";
import { Journey } from "./types";
import randomGenerator from "../utils/randomGenerator";

const randomPicker = <T extends {}>(options: Array<T>, randomCount: number): Array<T> => {
    const workingOptions = [...options];

    var optionCount = workingOptions.length;

    if (randomCount >= optionCount) {
        return workingOptions;
    }

    let randomOptions = new Array<T>();

    for (let index = 0; index < randomCount; index++) {
        const randomIndex = randomGenerator(0, workingOptions.length - 1);
        randomOptions = randomOptions.concat(workingOptions.splice(randomIndex, 1));
    }

    return randomOptions;
}

const generatePineappleJourney = (): Journey => {
    return {
        product: PINEAPPLE_PRODUCT,
        colour: randomPicker(PINEAPPLE_COLOURS, 1)[0],
        patterns: randomPicker(PINEAPPLE_PATTERNS, 2),
        twoDimensionals: randomPicker(PINEAPPLE_TWO_DIMENSIONAL, 2),
        threeDimensionals: randomPicker(PINEAPPLE_THREE_DIMENSIONAL, 2)
    }
}

const generateVanillaJourney = (): Journey => {
    return {
        product: VANILLA_PRODUCT,
        colour: randomPicker(VANILLA_COLOURS, 1)[0],
        patterns: randomPicker(VANILLA_PATTERNS, 2),
        twoDimensionals: randomPicker(VANILLA_TWO_DIMENSIONAL, 2),
        threeDimensionals: randomPicker(VANILLA_THREE_DIMENSIONAL, 2)
    }
}

export { generatePineappleJourney, generateVanillaJourney };